import styled from 'styled-components';

export const webDCNT = styled.div`
  iframe {
    width: 100vw;
    height: 100vh;
    border: none;
    margin: 0;
  }
`;
